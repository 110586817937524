const STORAGE = {
  //
  // ─── AUTH ───────────────────────────────────────────────────────────────────────
  //
  authToken: "auth:token",
  authMemberDetails: "memberDetails",
  authMemberBasicDetails: "memberBasicDetails",
  // cookiePolicyAccepted: "app:cookiePolicyAccepted",
  // cookiePolicyAcceptedValue: "1",
  cookiePolicies: "app:cookiePolicies",

  //
  // ─── APP ────────────────────────────────────────────────────────────────────────
  //
  country: "app:country",
  clientUnique: "app:clientUnique",
  loanFlowParams: "app:loanFlowParams:",
  loanFlowIdFromLoanApply: "app:loanFlowIdFromLoanApply:",
  hasActiveLoan: "app:hasActiveLoan",

  reduxPersistPrefix: "myk:store_persist:",
  hint: btoa("otp-pin-hint-values"),

  campaignParams: "campaignParams",
  campaignInfo: "campaignInfo",

  repayTokenForPayment: "repayTokenForPayment",

  hasPinRecentlyRequested: "hasPinRecentlyRequested",

  userApplicationLandingPage: "userApplicationLandingPage",

  widgetSessionId: "loanRequest:widgetSessionId",

  marketingPopUpLastShown: "app:marketingPopUp",

  affiliateSessionId: "app:affiliateSessionId",

  bizumSession: "app:bizumSession"
};


export default STORAGE;
