// plugins
import defaultsPlugin from "store/plugins/defaults";
import eventsPlugin from "store/plugins/events";
import expirePlugin from "store/plugins/expire";
import engine from "store/src/store-engine";
// stores
import cookieStorage from "store/storages/cookieStorage";
import localStorage from "store/storages/localStorage";
import memoryStorage from "store/storages/memoryStorage";
import sessionStorage from "store/storages/sessionStorage";

// import CryptoService from "core/CryptoService";

import ENV from "../constants/ENV";

const plugins = [
  expirePlugin,
  getAllPlugin,
  defaultsPlugin,
  eventsPlugin
  // cryptoPlugin
];

const namespace = (() => {
  try {
    if (import.meta.env.DEV) {
      const url = new URL(ENV.API_ENDPOINT);
      const api =  url.host.replace(/\W/gi, "_");
      return `__${api}__`;
    }
    return undefined;
  }
  catch (err) {
    return undefined;
  }
})();

const Local = engine.createStore([ localStorage ], plugins, namespace);
const Session = engine.createStore([ sessionStorage ], plugins, namespace);
const Memory = engine.createStore([ memoryStorage ], plugins, namespace);
const Cookie = engine.createStore([ cookieStorage ], plugins, namespace);
// function cryptoPlugin() {
//   const encryptedStore = this.createStore(this.storage, null, "enc");

//   return {
//     setEncrypted(superFn, key, value, secret) {
//       encryptedStore.set(
//         CryptoService.encrypt(key, secret),
//         CryptoService.encrypt(value, secret)
//       );
//       return superFn();
//     },
//     getDecrypted(superFn, key, secret) {
//       const qqq = encryptedStore.get(
//         CryptoService.encrypt(key, secret),
//       );
//       return superFn();
//     }
//   };
// }


function getAllPlugin() {
  return {
    getAll: function getAll() {
      const res = {};
      this.each((val, key) => {
        res[key] = val;
      });
      return res;
    },
    keys: function keys() {
      const keysArray = [];
      this.each((val, key) => {
        keysArray.push(key);
      });
      return keysArray;
    }
  };
}



// function expirePlugin() {
//   const expirations = this.createStore(this.storage, null, `${this._namespacePrefix}expire_key`);
//   return {
//     set: expireSet,
//     get: expireGet,
//     remove: expireRemove,
//     getExpiration,
//     removeExpiredKeys
//   };
//   function expireSet(superFn, key, val, expiration) {
//     if (!this.hasNamespace(namespace)) {
//       const expr = (expiration < (new Date([ 1970 ])).getTime()) ?
//         (new Date()).getTime() + expiration :
//         expiration;
//       expirations.set(key, expiration);
//     }
//     return superFn();
//   }
//   function expireGet(superFn, key) {
//     if (!this.hasNamespace(namespace)) {
//       _checkExpiration.call(this, key);
//     }
//     return superFn();
//   }
//   function expireRemove(superFn, key) {
//     if (!this.hasNamespace(namespace)) {
//       expirations.remove(key);
//     }
//     return superFn();
//   }
//   function getExpiration(_, key) {
//     return expirations.get(key);
//   }
//   function removeExpiredKeys(_) {
//     const keys = [];
//     this.each((val, key) => {
//       keys.push(key);
//     });
//     for (let i = 0; i < keys.length; i++) {
//       _checkExpiration.call(this, keys[i]);
//     }
//   }
//   function _checkExpiration(key) {
//     const expiration = expirations.get(key, Number.MAX_VALUE);
//     if (expiration <= new Date().getTime()) {
//       this.raw.remove(key);
//       expirations.remove(key);
//     }
//   }
// }



// function encryptionPlugin() {
//   return {
//     setSecure: function setSecure(cb, key, val) {
//       console.log("val ", val);
//       console.log("key ", key);
//       const keyEncrypted = (key);
//       console.log("keyEncrypted ", keyEncrypted);
//       const valEncrypted = (val);
//       console.log("valEncrypted ", valEncrypted);
//       this.set(keyEncrypted, valEncrypted);
//       cb();
//       return true;
//     },
//     getSecure: function getSecure(cb, key) {
//       const keyEncrypted = (key);
//       const valEncrypted = this.get(keyEncrypted);
//       const val = (valEncrypted);
//       return val;
//     }
//   };
// }


try {
  [
    Local,
    Session,
    Memory,
    Cookie
  ].forEach(storage => {
    const keysGroupWithversions = (storage.keys() || [])
      .filter(key => (/.*_v[0-9.]+$/gi).test(key))
      .reduce((acc, item) => {
        const key = item.split("_v")[0];
        const vers = item.split("_v")[1];
        return Object.assign(
          {},
          acc,
          {
            [key]: [
              ...(acc[key] || []),
              Number(vers)
            ].sort((a, b) => a - b)
          }
        );
      }, {});
    Object.entries(keysGroupWithversions)
      .forEach(([ key = "", versionArray = [] ]) => {
        versionArray.forEach(v => {
          if (v !== Math.max(...versionArray)) {
            const expiredKey = `${key}_v${v}`;
            storage.remove(expiredKey);
          }
        });
      });

  });
}
catch (err) {
  ErrorLoggerService("APP", "Storage", err);
}

export default {
  Local,
  Session,
  Memory,
  Cookie
};
